import React from 'react'
import { graphql } from 'gatsby'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import Seo from '../components/seo/seo'

const InformazioniLegali = ({ data }) => {

    return (
      <>
        <Seo
          title={data.markdownRemark.frontmatter.title}
        />
        <SiteNavigation />
        <section className="hero is-gradient is-medium">
          <div className="hero-body">
            <div className="content is-large" style={{ paddingTop: '100px' }}>
              <h1 className="text-center title is-white">
                {data.markdownRemark.frontmatter.title}
              </h1>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div
                className="prose"
                style={{
                  color: '#111',
                  lineHeight: 1.6,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </div>
            </div>
          </div>
        </section>
        <CommonFooter />
      </>
    )
  }

export default InformazioniLegali

export const infolegaliQuery = graphql`
  query LegalInfo($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        title
      }
    }
  }
`
